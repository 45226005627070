.StudyPage {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.StudyPage-container {
  flex-grow: 1;
  position: relative;
}

.StudyPage-containerInner {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
