.EditExtraCorrectAnswerModal-chosenItems {
  border: 1px solid #777;
  border-radius: 3px;
}

.EditExtraCorrectAnswerModal-wordBank {
  background: #DDD;
  border-radius: 3px;
}

.EditExtraCorrectAnswerModal-translateText {
  margin: 10px 0;
  font-weight: lighter;
}

.EditExtraCorrectAnswerModal-wordBankLabel {
  padding: 25px 0 10px;
  color: #777;
  text-transform: uppercase;
  font-size: 13px;
}

.EditExtraCorrectAnswerModal-hint {
  font-size: 13px;
  color: #AAA;
  font-weight: lighter;
}

.EditExtraCorrectAnswerModal-footerButton {
  display: inline-block;
  margin-right: 10px;
}

.EditExtraCorrectAnswerModal-failureReason {
  opacity: 1;
  font-size: 13px;
  transition: all 300ms;
  color: #D33;
  margin-bottom: 0;
}
.EditExtraCorrectAnswerModal-failureReason.is-hidden {
  opacity: 0;
}
