.Spinner {
  animation-name: Spinner-spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  font-size: 16px;
  opacity: 0.7;
}

.Spinner.Spinner--large {
  font-size: 36px;
}

@keyframes Spinner-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
