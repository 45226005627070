.AddWorksheetModalWorksheet-tidbits {
  color: #999;
}

.AddWorksheetModalWorksheet-tidbitSeparator {
  display: inline-block;
  margin: 0 10px;
  border-left: 1px solid #ddd;
  height: 15px;
  vertical-align: middle;
}
