.App {
  text-align: center;
  direction: ltr;
}

.App.locale-en {
  font-family: 'Open Sans', sans-serif;
}
.App.locale-zh {
  font-family: 'Hiragino Sans GB', 'Open Sans' , Arial, 'Microsoft YaHei', \\5FAE\8F6F\96C5\9ED1, STHeiti, 'WenQuanYi Micro Hei', SimSun, sans-serif;
}

.App-widget {
  margin: 0 auto;
  max-width: 600px;
  min-height: 100vh;
}
