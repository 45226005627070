.Worksheet {
  background: white;
}

.Worksheet-viewLink {
  transition: all 300ms;
  display: block;
  border: 1px solid #fff;
}
.Worksheet-viewLink:hover {
  border: 1px solid #ddd;
  border-bottom-color: #fff;
}

.Worksheet-tidbits {
  color: #999;
}

.Worksheet-tidbitSeparator {
  display: inline-block;
  margin: 0 10px;
  border-left: 1px solid #ddd;
  height: 15px;
  vertical-align: middle;
}

.Worksheet-share {
  display: flex;
  border-top: 1px solid #ddd;
}

.Worksheet-shareLabel {
  line-height: 40px;
}

.Worksheet-shareInput {
  border: 1px solid #e6e6e6;
  height: 40px;
}

.Worksheet-shareInputBox {
  flex-grow: 1;
}
