body {
  margin: 0;
  padding: 0;
  direction: ltr;
}

* {
  -webkit-rtl-ordering: visual;
}

a {
  color: #1c73a5;
}

.blue {
  color: #3097d1;
}

.dark-blue {
  color: #1c73a5;
}

.bg-blue {
  background-color: #3097d1;
}

.b--blue {
  border-color: #3097d1;
}

.green {
  color: #5cb85c;
}

.flex-no-shrink {
  flex-shrink: 0;
}

.react-toggle--checked .react-toggle-track {
  background-color: #48a648;
}

.react-toggle--focus .react-toggle-thumb {
  box-shadow: none;
}

.mw-50 {
  max-width: 50%;
}

@media screen and (min-width: 60em) {
  .mw-50-l {
    max-width: 50%;
  }
}

/* based on https://github.com/Fauntleroy/react-simple-dropdown/blob/master/styles/Dropdown.css */

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown__content {
  display: none;
  position: absolute;
  right: 0;
  top: 100%;
}

.dropdown--active .dropdown__content {
  display: block;
  z-index: 99;
}
