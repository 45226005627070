.BillingForm-input {
  border-radius: 4px;
  font-size: 20px;
  line-height: 24px;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  transition: all 300ms;
  border: 1px solid #e3e3e3;
}

.BillingForm-input:focus {
  box-shadow: none;
  outline: 0;
  border: 1px solid #aaaaaa;
}
