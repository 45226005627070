.Tabs {
  border-bottom: 1px solid #ddd;
  display: flex;
}

.Tab {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: relative;
  bottom: -1px;
  cursor: pointer;
  font-size: 14px;
  color: #777;
  border: 1px solid rgba(0, 0, 0, 0);
}
.Tab:hover {
  background: #ddd;
}

.Tab.is-active {
  border: 1px solid #ddd;
  border-bottom: 1px solid #eee;
}
.Tab.is-active:hover {
  background: none;
}
