.ImageChooser-hint {
  font-size: 13px;
  color: #AAA;
  font-weight: lighter;
  clear: both;
  margin-bottom: 10px;
}

.ImageChooser-searchResults {
  padding-top: 2px;
}

.ImageChooser-searchResult {
  width: 54px;
  height: 54px;
  cursor: pointer;
  border: 2px solid rgba(0,0,0,0);
  display: block;
}

.ImageChooser-searchResult.is-selected,
.ImageChooser-searchResultSpacer:hover .ImageChooser-searchResult.is-selected {
  border: 2px solid #357edd;
}
.ImageChooser-searchResultSpacer:hover .ImageChooser-searchResult {
  border: 2px solid black;
}

.ImageChooser-imageTarget {
  height: 200px;
  width: 200px;
  position: relative;
  margin: 0 auto;
  background: #DDD;
  margin-bottom: 20px;
}

.ImageChooser-uploadButton {
  margin: 0 auto;
  width: 200px;
}

.ImageChooser-deleteImageButton {
  position: absolute;
  font-size: 14px;
  top: 5px;
  right: 5px;
  background: rgba(255,255,255,0.7);
  color: #555;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: block;
  border: none;
  transition: all 300ms;
  border-radius: 1px;
}
.ImageChooser-deleteImageButton:hover {
  color: #222;
  background: rgba(255,255,255,0.9);
}

.ImageChooser-selectedImage,
.ImageChooser-hoveringImage {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 200px;
  height: 200px;
  border: 2px solid #000;
}

.ImageChooser-selectedImage,
.ImageChooser-hoveringImage.is-selected {
  border: 2px solid #357edd;
}
