.Flash {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

.Flash-message {
  margin: 20px auto;
  width: 300px;
  padding: 10px;
  border-radius: 4px;
  color: #555;
  background: white;
  box-shadow: 0 0 2px rgba(0,0,0,0.03);
  display: none;
  transition: all 1000ms 4000ms;
}

.Flash-message.message-appear,
.Flash-message.message-enter {
  display: block;
  opacity: 1;
}
.Flash-message.message-appear-active,
.Flash-message.message-enter-active {
  display: block;
  opacity: 0;
}
