.EmbedHeader-logo {
  font-family: 'Open Sans', sans-serif;
}

.EmbedHeader-dropdownLink {
  cursor: pointer;
  color: #333;
  width: 120px;
}
.EmbedHeader-dropdownLink:hover {
  background: rgba(0,0,0, 0.05);
}

.EmbedHeader-feedbackBanner {
  background: #5cb85c;
}
