.Button {
  display: inline-block;
  cursor: pointer;
  border: 1px solid;
  outline: none;
  color: white;
  font-size: 16px;
  border-radius: 4px;
  transition: all 300ms;
  text-decoration: none;
  position: relative;
  text-align: center;
}
.Button:disabled {
  cursor: no-drop;
  color: #ddd;
}

.Button-content {
  transition: opacity 300ms;
  opacity: 1;
}
.Button.is-loading .Button-content {
  opacity: 0;
}

.Button--small {
  padding: 5px;
}
.Button--medium {
  padding: 10px 20px;
}
@media (max-width: 400px) {
  .Button--medium {
    padding: 10px;
  }
}
.Button--large {
  padding: 15px 30px;
  font-size: 20px;
}

.Button--default {
  background: #17a2b8;
  border-color: #17a2b8;
}
.Button--default:hover {
  background: #138496;
  border-color: #138496;
}
.Button--default:disabled {
  background: #17a2b8;
  border-color: #17a2b8;
}
.Button--default.Button--outline {
  color: #17a2b8;
}
.Button--default.Button--outline:hover {
  color: #138496;
}

.Button--gray {
  background: #aaa;
  border-color: #aaa;
}
.Button--gray:hover {
  background: #333;
  border-color: #333;
}
.Button--gray:disabled {
  background: #aaa;
  border-color: #aaa;
}
.Button--gray.Button--outline {
  color: #aaa;
}
.Button--gray.Button--outline:hover {
  color: #333;
}
.Button--gray.Button--outline:disabled,
.Button--gray.Button--outline:hover:disabled {
  background: inherit;
  color: #ccc;
  border-color: #ccc;
}

.Button--success {
  background: #5cb85c;
  border-color: #5cb85c;
}
.Button--success:hover {
  background: #48a648;
  border-color: #48a648;
}
.Button--success:disabled {
  background: #5cb85c;
  border-color: #5cb85c;
}
.Button--success.Button--outline {
  color: #5cb85c;
}
.Button--success.Button--outline:hover {
  color: #48a648;
}

.Button--failure {
  background: #d9534f;
  border-color: #d9534f;
}
.Button--failure:hover {
  background: #d23430;
  border-color: #d23430;
}
.Button--failure:disabled {
  background: #d9534f;
  border-color: #d9534f;
}
.Button--failure.Button--outline {
  color: #d9534f;
}
.Button--failure.Button--outline:hover {
  color: #d23430;
}

.Button--fullWidth {
  width: 100%;
  display: block;
  box-sizing: border-box;
}

.Button--outline,
.Button--outline:hover {
  background: none;
}
.Button--phantomOutline {
  border-color: rgba(255, 255, 255, 0);
}

.Button--transparentDark {
  color: #eee;
  background: rgba(0, 0, 0, 0.3);
  border: none;
}
.Button--transparentDark:hover {
  background: rgba(0, 0, 0, 0.5);
}
.Button--transparentDark:disabled {
  color: rgba(255, 255, 255, 0.5);
}

.Button-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
  opacity: 0;
  transition: opacity 300ms;
}
.Button.is-loading .Button-spinner {
  opacity: 1;
}

.Button--outline:disabled {
  background: inherit;
}
