.MultipleChoiceAnswerDetails-choices.is-correct {
  border:2px solid #5cb85c;
}
.MultipleChoiceAnswerDetails-choices.is-incorrect {
  border:2px solid #d9534f;
}

.MultipleChoiceAnswerDetails-answer {
  display: flex;
  padding: 7px 0;
}
