.FormGroup {
  text-align: left;
  margin-bottom: 25px;
  opacity: 1;
  transition: all 300ms;
}
.FormGroup.is-hidden {
  opacity: 0;
  display: none;
}
.FormGroup.no-margin {
  margin-bottom: 0;
}

.FormGroup-hint {
  font-size: 13px;
  color: #AAA;
  font-weight: lighter;
  clear: both;
}
