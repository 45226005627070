.Modal {
  z-index: 1000;
}

.Modal-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  transition: all 300ms;
  z-index: 1001;
}

.Modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 300ms;
  z-index: 1001;
  max-height: calc(100vh - 40px);
  border-radius: 5px;
  overflow-y: scroll;
}

.Modal.is-default-width .Modal-container {
  width: 600px;
}
@media (max-width: 600px) {
  .Modal.is-default-width .Modal-container {
    width: 100%;
    margin: 0;
  }

  .Modal.is-default-width .Modal-main {
    margin: 0 10px;
  }
}

.Modal.is-narrow-width .Modal-container {
  width: 400px;
}
@media (max-width: 400px) {
  .Modal.is-narrow-width .Modal-container {
    width: 100%;
    margin: 0;
  }

  .Modal.is-narrow-width .Modal-main {
    margin: 0 10px;
  }
}

.Modal-main {
  background: #eee;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.Modal-backdrop.toggle-enter,
.Modal-container.toggle-enter {
  opacity: 0;
}

.Modal-backdrop.toggle-enter-active,
.Modal-container.toggle-enter-active {
  opacity: 1;
}

.Modal-backdrop.toggle-leave,
.Modal-container.toggle-leave {
  opacity: 1;
}

.Modal-backdrop.toggle-leave-active,
.Modal-container.toggle-leave-active {
  opacity: 0;
}

.Modal-header {
  padding: 20px 10px;
  border-bottom: 1px solid #ccc;
  margin: 0 0 10px;
  position: relative;
  text-align: center;
}

.Modal-headerCloseButton {
  top: 10px;
  right: 10px;
  background: none;
  border: 1px solid #ccc;
  position: absolute;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  color: #999;
  cursor: pointer;
  transition: all 300ms;
}

.Modal-headerCloseButton:hover {
  border: 1px solid #333;
  color: #333;
}

.Modal-body {
  padding: 10px;
  text-align: left;
}

.Modal-footer {
  padding: 10px;
  text-align: left;
  border-top: 1px solid #ccc;
  margin: 10px 0 0;
}
