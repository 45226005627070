.DemoExerciseButton-flag {
  background-size: cover;
  background-position: center;
  position: absolute;
  opacity: 0.1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.DemoExerciseButton-flag.spain {
  background-image: url('/flags/spain.png');
}
.DemoExerciseButton-flag.china {
  background-image: url('/flags/china.png');
}
.DemoExerciseButton-flag.france {
  background-image: url('/flags/france.png');
}
.DemoExerciseButton-flag.unitedKingdom {
  background-image: url('/flags/united_kingdom.png');
}
