.AnswerDetails {
  background: #DDD;
}

.AnswerDetails-result {
  color: #d9534f;
}
.AnswerDetails-result.is-correct {
  color: #5cb85c;
}
