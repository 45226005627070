.WorksheetAuth {
  background: #eee;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.WorksheetAuth-vertCenter {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
