.WordSplitter {
  position: relative;
  width: 100%;
}

.WordSplitter-atom {
  position: absolute;
  padding: 0 10px;
  background: white;
  border-radius: 4px;
  transition: all 300ms;
}

.WordSplitter-joiner,
.WordSplitter-splitter  {
  font-size: 14px;
  position: absolute;
  transition: all 300ms;
  color: #CCC;
  cursor: pointer;
  text-align: center;
  z-index: 1;
  background-color: rgba(255,255,255,0);
}

.WordSplitter-splitter {
  background: rgba(255, 255, 255, 1);
}


.WordSplitter-joiner.is-hovering {
  font-size: 12px;
  width: 30px;
  color: #333;
  background-color: rgba(255,255,255,1);
}

.WordSplitter-splitter.is-hovering {
  background-color: rgba(255,255,255,0);
  font-size: 14px;
  color: #333;
}
