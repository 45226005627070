.CheckBox {
  background: white;
  height: 24px;
  width: 24px;
  border: 2px solid;
  transition: all 300ms;
  flex-shrink: 0;
  text-align: center;
}

.CheckBox-checkmark {
  line-height: 20px;
  vertical-align: top;
  opacity: 0;
  transition: all 300ms;
}
.CheckBox.is-checked .CheckBox-checkmark {
  opacity: 1;
}
