.WordOrderPositioner {
  position: relative;
}

.WordOrderPositioner-wordPart {
  position: absolute;
  top: 0;
  left: 0;
  transition: all 300ms;
  z-index: 99;
}

.WordOrderPositioner-wordPart.is-dragging {
  transition: box-shadow 300ms, top 150ms, left 150ms;
  top: -3px;
  left: -3px;
  z-index: 100;
  box-shadow: 3px 3px 15px rgba(0,0,0,0.15);
}
