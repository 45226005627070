.StudyRecord {
  background: white;
  align-items: center;
}

.StudyRecord-info {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  border-top: 1px solid #ddd;
  padding: 0.25rem 0;
  flex-grow: 1;
}
.StudyRecord.has-worksheet .StudyRecord-info {
  flex-grow: 0;
}

.StudyRecord-stats {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.StudyRecord-studyButton {
  margin-right: 0.25rem;
}

.StudyRecord-viewLink {
  transition: all 300ms;
  display: block;
  border: 1px solid #fff;
  flex-grow: 1;
  min-width: 0;
}
.StudyRecord-viewLink:hover {
  border: 1px solid #ddd;
  border-bottom-color: #fff;
}

@media (min-width: 750px) {
  .StudyRecord {
    display: flex;
  }

  .StudyRecord-info {
    border-top: none;
    padding: 0;
  }

  .StudyRecord-studyButton {
    margin-right: 0.5rem;
  }

  .StudyRecord-viewLink:hover {
    border-bottom-color: #ddd;
  }

  .StudyRecord-dateCompleted {
    text-align: right;
  }
}

.StudyRecord-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 25px;
}

.StudyRecord-userLink,
.StudyRecord-anon {
  line-height: 25px;
  border: 1px solid #fff;
  flex-grow: 1;
}
.StudyRecord-userLink:hover {
  border: 1px solid #ddd;
}

.StudyRecord-score {
  color: #17a2b8;
  width: 60px;
  text-align: center;
}

.StudyRecord-duration {
  width: 30px;
}

.StudyRecord-dateCompleted {
  width: 130px;
}

.StudyRecord-hiddenScoreLink {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
