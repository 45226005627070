.DraggableExercise {
  position: relative;
  transition: box-shadow 300ms;
}

.DraggableExercise.is-dragging {
  box-shadow: 3px 3px 15px rgba(0,0,0,0.15);
}

.DraggableExercise-buttons {
  position: absolute;
  right: 10px;
  top: 10px;
}

.DraggableExercise-buttonIcon {
  width: 16px;
  text-align: center;
}
