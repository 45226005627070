.OauthHeader-orContainer {
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  text-align: center;
  display: block;
  text-align: center;
}

.OauthHeader-or {
  background: white;
  padding: 0 10px;
  color: #AAA;
}

.OauthHeader-or.is-grayBg {
  background: #EEE;
}
