.TeacherBadge {
  background: #3097d1;
  font-weight: bold;
  color: white;
  border-radius: 3px;
  padding: 2px 8px;
}

.TeacherBadge--small {
  font-size: 9px;
  padding: 0 5px;
}
