.VocabExerciseSummary-term {
  position: relative;
}

.VocabExerciseSummary-term.has-image {
  padding-right: 64px;
  min-height: 54px;
}

.VocabExerciseSummary-termImage {
  display: block;
  height: 54px;
  width: 54px;
  right: 0;
  top: 0;
  position: absolute;
}
