
.VocabAnswerDetails-term {
  padding: 2px;
}
.VocabAnswerDetails-term.is-correct {
  background: #5cb85c;
}
.VocabAnswerDetails-term.is-incorrect {
  background: #d9534f;
}

.VocabAnswerDetails-termInner.has-image {
  padding-right: 64px;
  min-height: 54px;
}
.VocabAnswerDetails-termInner {
  position: relative;
}

.VocabAnswerDetails-termImage {
  display: block;
  height: 54px;
  width: 54px;
  right: 0;
  top: 0;
  position: absolute;
}
