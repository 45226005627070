.Distractor {
  padding: 5px;
  background: white;
  border-radius: 4px;
}

.Distractor-textInput input {
  min-width: 20px;
  border: 1px solid #EEE;
  border-radius: 3px;
  line-height: 24px;
  font-size: 16px;
  outline: none;
  padding: 0 2px;
}

.Distractor-textInput input:focus {
  border: 1px solid #99F;
}

.Distractor-text {
  font-size: 16px;
  line-height: 23px;
  padding-left: 5px;
}

.Distractor-button {
  border-radius: 3px;
  border: none;
  background: rgba(0,0,0,0.1);
  margin-left: 3px;
  transition: all 300ms;
  cursor: pointer;
  line-height: 24px;
  font-size: 16px;
}

.Distractor-button.Distractor-saveChanges:hover {
  background: rgba(0,150,0,0.4);
}

.Distractor-button.Distractor-delete {
  color: #CCC;
  background: rgba(150,0,0,0);
}
.Distractor-button.Distractor-delete:hover {
  color: #333;
  background: rgba(150,0,0,0.4);
}
