.FullScreenSlideoutModal {
  z-index: 2000;
}

.FullScreenSlideoutModal-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  transition: all 300ms;
  z-index: 1001;
}

.FullScreenSlideoutModal-container {
  position: fixed;
  right: 0;
  top: 0;
  transition: transform 300ms;
  z-index: 2001;
  height: 100%;
  width: 85%;
  overflow-y: scroll;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.FullScreenSlideoutModal-backdrop.toggle-enter {
  opacity: 0;
}

.FullScreenSlideoutModal-container.toggle-enter {
  transform: translateX(100%);
}

.FullScreenSlideoutModal-backdrop.toggle-enter-active {
  opacity: 1;
}

.FullScreenSlideoutModal-container.toggle-enter-active {
  transform: translateX(0%);
}

.FullScreenSlideoutModal-backdrop.toggle-leave {
  opacity: 1;
}

.FullScreenSlideoutModal-container.toggle-leave {
  transform: translateX(0%);
}

.FullScreenSlideoutModal-backdrop.toggle-leave-active {
  opacity: 0;
}

.FullScreenSlideoutModal-container.toggle-leave-active {
  transform: translateX(100%);
}

.FullScreenSlideoutModal-header {
  padding: 20px 10px;
  margin: 0 0 10px;
}

.FullScreenSlideoutModal-headerCloseButton {
  top: 10px;
  right: 10px;
  background: none;
  position: absolute;
  width: 40px;
  height: 40px;
  color: #eee;
  border: 1px solid rgba(255, 255, 255, 0.3);
  cursor: pointer;
  transition: all 300ms;
}

.FullScreenSlideoutModal-headerCloseButton:hover {
  border: 1px solid white;
  color: white;
}

.FullScreenSlideoutModal-body {
  text-align: left;
}
