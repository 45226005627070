.GoogleAuthButton {
  background-color: #4285f4;
  display: block;
  padding: 2px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.25);
  text-decoration: none;
  display: flex;
  align-items: center;
  border-radius: 1px;
  transition: background-color .218s,border-color .218s,box-shadow .218s;
}

.GoogleAuthButton:hover {
  box-shadow: 0 0 3px 3px rgba(66,133,244,.3);
}

.GoogleAuthButton-logo {
  background: white;
  display: block;
  padding: 10px;
  height: 18px;
  width: 18px;
  border-radius: 1px;
}

.GoogleAuthButton-svg {
  display: block;
}

.GoogleAuthButton-text {
  display: inline-block;
  margin-left: 10px;
  vertical-align: top;
  font-size: 14px;
  color: white;
}
