.MobileMenuDivider {
  position: relative;
}

.MobileMenuDivider-label {
  position: absolute;
  line-height: 20px;
  padding: 0 10px;
  left: 20px;
  top: -11px;
}
