.ImagePreview {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  position: relative;
}

.ImagePreview-editButton,
.ImagePreview-addButton {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  height: 100px;
  width: 100px;
  text-align: center;
  background: rgba(0,0,0,0.3);
  transition: all 300ms;
  color: #DDD;
  border-radius: 3px;
}

.ImagePreview-addButton {
  line-height: 26px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ImagePreview-editButton {
  opacity: 0;
}
.ImagePreview-editButton:hover {
  opacity: 1;
}

.ImagePreview-guessedImage,
.ImagePreview-selectedImage {
  border-radius: 3px;
}

.ImagePreview-guessedImage {
  opacity: 0.2;
  transition: all 300ms;
}
.ImagePreview:hover .ImagePreview-guessedImage {
  opacity: 0.6;

}
