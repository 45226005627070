.Input {
  border-radius: 4px;
  font-size: 20px;
  line-height: 24px;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  transition: all 300ms;
  border: 1px solid #E3E3E3;
}

.Input:focus {
  box-shadow: none;
  outline: 0;
  border: 1px solid #AAAAAA;
}

.Input::placeholder {
  color: #CCC;
  font-weight: lighter;
}
